import { Button } from 'react-bootstrap';

export default function EventResponse() {

    return (
                <div>
                    That's too bad. We'll miss you!<br /><br />
                    <i>(This part doesn't work yet... please let the organiser know you can't make it)</i>
                </div>
    );
}